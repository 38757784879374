#nprogress {
  .bar {
    @layer components
    @apply h-1 bg-primary-500;
  }
  
  .peg {
    @layer components
    @apply shadow-[0_0_10px] shadow-primary-500;
  }
}