@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
}

/* @import "./typography.css"; */
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.embla {
  --slide-spacing: 1rem;
  --slide-size: 33%;
  --slide-height: 19rem;
  padding: 1.6rem;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}
.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}
.embla__slide__number {
  width: 4.6rem;
  height: 4.6rem;
  z-index: 1;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 4.6rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}
.embla__slide__number > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.embla__button {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}
.embla__buttons {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.6rem;
}
.embla__button {
  z-index: 1;
  color: var(--background-site);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
}
.embla__button:disabled {
  opacity: 0.3;
}
.embla__button__svg {
  width: 65%;
  height: 65%;
}
.embla__dot {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}
.embla__dots {
  z-index: 1;
  bottom: 1.6rem;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.embla__dot {
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.embla__dot:after {
  background: var(--background-site);
  border-radius: 0.2rem;
  width: 100%;
  height: 0.3rem;
  content: '';
}
.embla__dot--selected:after {
  background: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
}

.custom-dashed-border {
  background-image: linear-gradient(
      to right,
      #9bb1bb 0 50%,
      transparent 50% 100%
    ),
    linear-gradient(to right, #9bb1bb 0 50%, transparent 50% 100%);
  background-size: 2% 1px;
}

.folded-ribbon {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  clip-path: polygon(0% 0%, 100% 0%, 95% 50%, 100% 100%, 0% 100%, 5% 50%);
  background: #ffde59;
  height: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Google Custom Search Engine styling */
.gsc-results-wrapper-overlay {
  border-radius: 20px !important;
}

.gs-title {
  color: #0057b7 !important;
}

.gs-webResult .gs-visibleUrl {
  color: #07abff !important;
}

.gsc-input .gsc-input-box input {
  background: none !important;
  --tw-ring-inset: none !important;
}

input#gsc-i-id1.gsc-input:focus + .gsc-input-box {
  border: 1px solid #00a6fb !important;
}

.gsc-search-button-v2 {
  padding: 8px 27px !important;
}

.hide-close-button[data-radix-dialog-close] {
  display: none;
}

.gsc-control-cse {
  padding: 0 !important;
}

.gsc-input-box {
  padding: 6px 18px;
  border: 1px solid #e1e8eb;
  height: 50px;
  padding-left: 35px;
  background: url(/images/search.png) no-repeat left !important;
  background-size: 20px !important;
  background-position: 10px 12px !important;
  border-radius: 12px !important;
}

.gsc-search-button {
  border-radius: 12px !important;
  height: 50px;
  width: 100px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-color: none !important;
  border: none !important;
  background-color: #0057b7 !important;
}

.gsc-search-button svg {
  width: 17px;
  height: 17px;
}

.gsc-selected-option-container {
  border-radius: 12px !important;
  height: 50px !important;
}

.gsc-orderby {
  height: 50px !important;
}

.gsc-orderby-label {
  line-height: 50px !important;
  color: #49565b;
  font-size: 14px;
  font-weight: 400;
}

.gsc-selected-option {
  color: #13191b;
  font-size: 16px;
  line-height: 50px;
  font-weight: 600;
  padding-left: 10px;
}

.gsc-result-info {
  @apply font-sans;
}

.gsc-result {
  @apply font-sans;
}

.gs-snippet {
  @apply font-sans;
  padding-left: 70px;
}

.gsc-above-wrapper-area {
  border-top: 1px solid #e9e9e9;
  border-bottom: none !important;
}

.gsc-result-info-container {
  padding-top: 15px !important;
}

.gs-result .gs-image {
  border: none !important;
}

.gsc-cursor-box {
  @apply font-sans;
  display: flex;
  justify-content: center;
}

.gsc-cursor-page {
  padding-right: 8px;
}

.gcsc-find-more-on-google {
  display: none !important;
}

.gcsc-find-more-on-google-branding {
  display: none !important;
}

.google-parent-container::-webkit-scrollbar {
  background: white !important;
  width: 10px !important;
}

.google-parent-container::-webkit-scrollbar-thumb {
  background: #d9d9d9 !important;
  -webkit-border-radius: 20px;
}

.google-parent-container:focus-visible {
  outline: none;
}

.google-parent-container :focus-visible {
  outline: none;
}

.no-results-header {
  @apply font-serif;
  font-size: 30px;
  font-weight: 900;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: center;
}

.iframe-background {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 70vh;
}

.iframe-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 70vh;
  transform: translate(-50%, -50%);
}

.progress-bar {
  width: 100%;
  background-color: #e8e8e8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.progress {
  height: 10px;
  background-color: #0057b7;
  transition: width 0.4s ease;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

.newsletter-input {
  -webkit-text-fill-color: white;
  caret-color: white;
}
